var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
const LinkedIn = (_a) => {
    var { unique = '' } = _a, props = __rest(_a, ["unique"]);
    return (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", fill: "none", viewBox: "0 0 24 24", role: "presentation", focusable: "false" }, props),
        React.createElement("path", { fill: "#000", d: "M19.667 3H4.333C3.6 3 3 3.567 3 4.3v15.4c0 .733.6 1.3 1.333 1.3h15.334C20.4 21 21 20.433 21 19.7V4.3c0-.733-.6-1.3-1.333-1.3M8.333 18.333H5.667v-8.6h2.666zM7 8.567a1.53 1.53 0 0 1-1.533-1.534c0-.866.7-1.533 1.533-1.533.867 0 1.533.7 1.533 1.533.034.834-.666 1.534-1.533 1.534m11.333 9.766h-2.666v-4.166c0-1-.034-2.267-1.4-2.267-1.4 0-1.6 1.1-1.6 2.2v4.233H10v-8.6h2.567V10.9h.033c.367-.667 1.233-1.4 2.533-1.4 2.7 0 3.2 1.767 3.2 4.1z" })));
};
/* eslint-enable no-unused-vars, @typescript-eslint/no-unused-vars */
export default LinkedIn;
